import store from "@/store";
import { apiAxios } from "@/store/apiAxios";

/*
 * Add a response interceptor
 */
apiAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/authUser"] &&
      !store.getters["auth/guest"]
    ) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

/* eslint-disable */
export default {
  async tfaDetails(payload: any) {
    await apiAxios.get("/sanctum/csrf-cookie");
    return apiAxios.post("/api/tfa-details", payload);
  },
  async loginEmail(payload: any) {
    return apiAxios.post("/api/check-user-email", payload);
    // return  payload;
  },
  async returnPayload(payload: any) {
    return payload;
    // return  payload;
  },
  async returnUserPhoneNumber(payload: any) {
    return apiAxios.post("/api/get-user-phone-number-from-email-login", payload);
  },
  async login(payload: any) {
    return apiAxios.post("/login", payload);
  },
  logout() {
    return apiAxios.post("/logout");
  },
  async forgotPassword(payload: any) {
    await apiAxios.get("/sanctum/csrf-cookie");
    return apiAxios.post("/forgot-password", payload);
  },
  getAuthUser() {
    return apiAxios.get("/api/users/auth");
  },
  getContactInformationUserAuth() {
    return apiAxios.get("/api/user/get-contact-information");
  },
  getContactDeatilsFromAC(id: any) {
    return apiAxios.get("/api/admin/get-contact-report", {params : {id}});
  },
  getActiveUserStatus(email: any) {
    return apiAxios.post("/api/get-user-active-status", email);
  },
  async resetPassword(payload: any) {
    await apiAxios.get("/sanctum/csrf-cookie");
    return apiAxios.post("/reset-password", payload);
  },
  updatePassword(payload: any) {
    return apiAxios.put("/user/password", payload);
  },
  async registerUser(payload: any) {
    await apiAxios.get("/sanctum/csrf-cookie");
    return apiAxios.post("/register", payload);
  },
  sendVerification(payload: any) {
    return apiAxios.post("/email/verification-notification", payload);
  },
  updateUser(payload: any) {
    return apiAxios.put("/api/user", payload);
  },
  addLog(payload: any) {
    return apiAxios.put("/api/logs", payload);
  }
};
