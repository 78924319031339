




import Vue from "vue";

export default Vue.extend({
  name: "LocaleSwitcher",
  data: () => ({
    availableLocales: ["en", "nl"],
  }),
  methods: {
    switchLocale(): void {
      if (this.$root.$i18n.locale == "en") {
        this.$root.$i18n.locale = "nl";
      } else {
        this.$root.$i18n.locale = "en";
      }
    },
  },
});
