interface MiddlewarePipeline {
  (): void;
}

export default function middlewarePipeline(
  context: Record<string, unknown>,
  middleware: any[],
  index: number
): MiddlewarePipeline {
  const nextMiddleware = middleware[index];
  return () => {
    nextMiddleware({
      ...context,
      next: middlewarePipeline(context, middleware, index + 1),
    });
  };
}
