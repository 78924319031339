import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import payments from "./payments";
import loans from "./loans";
import application from "./application";
import account from "./account";
import user from "./user";
import logs from "./logs";
import bankstatement from "./bankstatement";
import * as auth from "./auth";
import { RootState } from "@/store/types/";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    payments,
    loans,
    auth,
    application,
    bankstatement,
    account,
    user,
    logs,
  },
  plugins: [
    createPersistedState({
      paths: ["application"],
    }),
  ],
});

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
    verifyingCode: any;
  }
}
