var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"id":"app"}},[(
      _vm.$store.state.auth.user !== null &&
      _vm.$route.name !== 'Login' &&
      _vm.$route.name !== 'Forgot Password' &&
      _vm.$route.name !== 'Two Factor' &&
      _vm.$route.name !== 'Reset Password'
    )?_c('TheNavbar'):_vm._e(),_c('div',{staticClass:"content"},[_c('router-view')],1),(
      _vm.$route.name !== 'Customer Service' &&
      _vm.$route.name !== 'Profile' &&
      _vm.$route.name !== 'Profile Edit' &&
      _vm.getFields !== 'admin' &&
      _vm.getFields !== 'all'
    )?_c('TheFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }