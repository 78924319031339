import { RootState, PaymentsState } from "@/store/types/";
import { Payment, Loan, LoanStatus } from "@/types/";
import { faLongArrowDown } from "@fortawesome/free-solid-svg-icons";
import { ActionContext, Commit, Dispatch } from "vuex";
import { apiAxios } from "@/store/apiAxios";

/* eslint-disable */

const state = {
  payments: [],
  fetched: false,
  paymentStatus: {
    failed: 0,
    applied: 1,
    paid: 2,
  },
};

const mutations = {
  setPayments(state: PaymentsState, payments: Payment[]): void {
    state.payments = payments;
    state.fetched = true;
  },
  setFetched(state: PaymentsState, fetched: boolean): void {
    state.fetched = fetched;
  },
  addPayments(
    state: PaymentsState,
    { data }: { data: Payment[]; loanId: number }
  ): void {
    const mergedData = data.reduce((arrayData: Payment[], item: Payment) => {
      const existingItem = arrayData.find((p: Payment) => p.payment_date === item.payment_date);
      if (existingItem) {
        // Sum the amount of the existing item and the current item
        existingItem.amount += item.amount;
      } else {
        // Add new item to the ArrayData
        arrayData.push(item);
      }
      return arrayData;
    }, []);
    state.payments.push(...mergedData);
    state.payments.sort(
      (a: Payment, b: Payment) => b.payment_date - a.payment_date
    );
  },
};
const getters = {
  getPayments(): Payment[] {
    return state.payments;
  },
  getPaymentsWithLoanData(
    _1: any,
    _2: any,
    _3: any,
    rootGetters: any
  ): Payment[] {
    const loans = rootGetters["loans/getLoans"];
    const purposeMap = loans.map((loan: Loan) => {
      return {
        id: loan.ac_deal_id,
        purpose: loan.purpose,
      };
    });
    const repayedLoanIds = loans.filter((loan: Loan) => loan.repayed >= loan.totalamount).map((loan: Loan) => loan.application_id)
    const failedPayments = loans.filter((loan: Loan) => loan.arrears == 0).map((loan: Loan) => loan.application_id)
    const payments = state.payments.map((payment: Payment) => {
      const copiedPayment = { ...payment }

      if (repayedLoanIds.includes(payment.application_id)) {
        copiedPayment.payment_status = "Confirmed"
      }

      if (failedPayments.includes(payment.application_id)) {
        if(copiedPayment.payment_status == "Rejected") {
          copiedPayment.payment_status = "Failed"
        }
      }

      const purpose = purposeMap.find(
        (p: any) => p.application_id === copiedPayment.application_id
      );

      return { ...copiedPayment, purpose: purpose?.purpose ?? "---" };
    });

    return payments;
  },
};

const actions = {
  fetchAllPayments(context: ActionContext<PaymentsState, RootState>): void {
    if (state.fetched === true) return;
    if (!context.rootGetters["loans/hasFetched"]) {
      context.dispatch("loans/fetchLoans", {}, { root: true }).then(() => {
        context.dispatch("fetchAllPayments");
      });
    } else {
      const loanIds = context.rootGetters["loans/getLoans"]
        .filter((loan: Loan) => loan.ac_deal_id !== null)
        .map((loan: Loan) => loan.ac_deal_id);
      loanIds.forEach((loanAppId: number) => {
        apiAxios.get("/api/payments/" + loanAppId).then((response) => {
          context.commit("addPayments", response.data);
        });
      });
      context.commit("setFetched", true);
    }
  },
  fetchSingleLoanPayments({
    rootGetters,
    commit,
    dispatch 
  } : {
    commit: Commit,
    dispatch: Dispatch,
    rootGetters: LoanStatus
  }): void {
    if (state.fetched === true) return;
    if (!rootGetters["loans/hasFetched"]) {
        dispatch("loans/fetchLoans", {}, { root: true }).then(() => {
        dispatch("fetchSingleLoanPayments");
      });
    } else {
      const loanIds = rootGetters["loans/getLoans"]
      .filter((loan: Loan) => loan.ac_deal_id !== null)
      .map((loan: Loan) => loan.ac_deal_id);

      const loans = rootGetters["loans/getLoans"];

      const currentTime = Date.now();
      let diff = loans.map((l: Loan) => {
        return Math.abs(currentTime - Number(l.loan_start_date));
      });

      let i = diff.indexOf(Math.min(...diff));
      
      if (i == -1) {
        i = loans.length - 1;
      }

      let selectedId = rootGetters["loans/selectedLoanId"];

      if (selectedId == null) {
        commit("loans/setSelectedLoanId", loans[i].ac_deal_id, { root: true });
        selectedId = rootGetters["loans/selectedLoanId"];
      }

      apiAxios.get("/api/payments/" + selectedId).then((response) => {
        commit("addPayments", response.data);

        loanIds.forEach((loanAppId: number) => {
          if(loanAppId !== selectedId) {
            apiAxios.get("/api/payments/" + loanAppId).then((r) => {
              commit("addPayments", r.data);
            });
          }
        });
      });

      commit("setFetched", true);
    }
  },
  export(): void {
    // is there a better way to do this?
    // is withCredentials respected? Seems to work with sanctum
    // also, no frontend-controlled loading bar might be confusing for the user in larger exports
    // TODO: don't hard code this at least
    window.location.href = "http://localhost/api/payments/export";
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
