import { RootState, LoansState } from "@/store/types/";
import { ActionContext, Commit, Dispatch } from "vuex";
import { Loan } from "@/types/";
import { apiAxios } from "@/store/apiAxios";

// Under the tab "loans" we would like to reduce the categories to "Current" and "Complete".
// Under current, it should show all current loans as well as requested loans.
// Under complete, it should show all loans repaid in full as well as rejected loans.
/* eslint-disable */

const state = {
  selectedLoanId: null,
  loans: [],
  fetched: false,
  loanStatus: {
    requested: 1,
    current: 2,
    repaid: 3,
    rejected: 4,
  },
  // documents: [] as Document[],
};

const mutations = {
  setSelectedLoanId(state: LoansState, selectedLoanId: number): void {
    state.selectedLoanId = selectedLoanId;
  },
  setLoans(state: LoansState, loans: Loan[]): void {
    state.loans = loans;
    state.fetched = true;
  },
  setFetched(state: LoansState, fetched: boolean): void {
    state.fetched = fetched;
  },
};
const getters = {
  selectedLoanId(): number | null {
    return state.selectedLoanId;
  },
  getLoans(): Loan[] {
    return state.loans;
  },
  getLoansByStatus:
    (state: LoansState) =>
    (status: string): Loan[] => {
      if (status === "current") {
        return state.loans.filter(
          (loan: Loan) => loan && loan.outstanding !== 0
        );
      }
      if (status === "complete") {
        return state.loans.filter(
          (loan: Loan) => loan && loan.outstanding === 0
        );
      }
      return state.loans.filter((loan: Loan) => loan.loan === false);
    },
  hasFetched(): boolean {
    return state.fetched;
  },
  /**
   * Active Loans are loans with a Loan Status
   * In Progress loans are saved, but now shown, thus not in this count
   *
   * RVK Loan statuses are not correct implemented yet
   * @returns number of active loans
   */
  getActiveLoans(): number {
    return state.loans.length;

    const activeLoanStatuses: string[] = ["Current", "Repaid", "Requested"];
    const activeLoans = state.loans.filter((loan: Loan) =>
      activeLoanStatuses.includes(loan.status)
    );
    return activeLoans.length;
  },
};

const actions = {
  fetchLoans({ commit }: { commit: Commit }): any {
    if (state.fetched === true) return;
    return apiAxios
      .get("/api/loans")
      .then((response) => commit("setLoans", response.data.data));
  },
  downloadLoanDocument(
    context: ActionContext<LoansState, RootState>,
    documentFile: any
  ): any {
    const url = documentFile.value;
    return apiAxios({
      method: "get",
      url: "/api/loans/documents",
      responseType: "blob",
      params: documentFile,
    }).then((response) => {
      // copy-pasted code, can it be improved? Seems hacky
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        url.substring(url.lastIndexOf("/") + 1)
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  },
  executePayment(
    context: ActionContext<LoansState, RootState>,
    {
      paymentId,
      amount,
      email,
      contactId,
      description,
      application_id,
      ac_deal_id,
    }: {
      paymentId: number;
      amount: number;
      email: string;
      contactId: number;
      description: string;
      application_id: number | string;
      ac_deal_id: number;
    }
  ): Promise<any> {
    return apiAxios({
      method: "post",
      url: "/api/transactions/executePayment",
      params: {
        paymentId,
        amount,
        email,
        contactId,
        description,
        application_id,
        ac_deal_id,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error));
  },
  hydrateLoans({
    commit,
    dispatch,
  }: {
    commit: Commit;
    dispatch: Dispatch;
  }): any {
    commit("setFetched", false);
    return dispatch("fetchLoans");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
