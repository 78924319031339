import { User } from "@/types";
import { RootState, UserState } from "./types";
import { apiAxios } from "@/store/apiAxios";
import { ActionContext } from "vuex";

const viewed = window.localStorage.getItem("popupViewed");

const state = {
  popupAlert: null as unknown as boolean,
  viewed: viewed === "false" ? false : true,
  titleBoxCustom: "",
  descriptionBoxCustom: "",
};

const mutations = {
  /**
   * @deprecated
   * @since 2023-08-09
   */
  setUsers(): void {
    throw new Error("Should use fetchUsers");
  },

  /**
   * @deprecated
   * @since 2023-08-09
   */
  setFetched(): void {
    throw new Error("Should use fetchUsers");
  },

  setPopupAlert(state: UserState, popupAlert: boolean): void {
    state.popupAlert = popupAlert;
  },

  setViewedPopup(state: UserState, viewed: boolean): void {
    state.viewed = viewed;

    window.localStorage.setItem("popupViewed", `${viewed}`);
  },

  setTitleBoxCustom(state: UserState, titleBoxCustom: string): void {
    state.titleBoxCustom = titleBoxCustom;
  },

  setDescriptionBoxCustom(
    state: UserState,
    descriptionBoxCustom: string
  ): void {
    state.descriptionBoxCustom = descriptionBoxCustom;
  },
};

const getters = {
  getPopupAlert(): boolean {
    return state.popupAlert;
  },

  getViewedPopup(): boolean {
    return state.viewed;
  },

  getTitleBoxCustom(): string {
    return state.titleBoxCustom;
  },

  getDescriptionBoxCustom(): string {
    return state.descriptionBoxCustom;
  },
};

const actions = {
  async fetchUsers(
    _unused: unknown,
    {
      page,
      limit,
      keyword,
    }: { page: number; limit: number; keyword: string | undefined }
  ): Promise<User[]> {
    const params = new URLSearchParams();
    params.append("page", `${page}`);
    params.append("limit", `${limit}`);

    if (keyword) {
      params.append("keyword", keyword);
    }
    const response = await apiAxios.get(
      `/api/admin/users-list?${params.toString()}`
    );

    return response.data;
  },

  /**
   * Should use the paginated response from fetchUsers
   *
   * @deprecated
   * @since 2023-05-09
   */
  fetchAllUsers(): unknown {
    throw new Error("Should use fetchUsers");
  },
  async popupAlert(
    context: ActionContext<UserState, RootState>
  ): Promise<void> {
    return await apiAxios.get("/api/admin/get-popup").then((r) => {
      r.data.active = r.data.active === 1 ? true : false;
      context.commit("setPopupAlert", r.data.active);
      context.commit("setTitleBoxCustom", r.data.title);
      context.commit("setDescriptionBoxCustom", r.data.description);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
