













import Vue from "vue";
import { BIconPersonCircle } from "bootstrap-vue";
import AuthService from "@/services/AuthService";

export default Vue.extend({
  components: {
    BIconPersonCircle,
  },
  computed: {
    userId(): number {
      const user = this.$store.getters["auth/authUser"];
      return user.id;
    },
  },
  methods: {
    logout() {
      const payload = {
        id: this.userId,
        type: "logout",
        step: "logout",
      };
      AuthService.addLog(payload);
      this.$store.dispatch("auth/logout");
    },
  },
});
