import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import BootstrapVue from "bootstrap-vue";
import "./assets/scss/custom.scss";
import firebase from "firebase/app";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

function detectMob() {
  const toMatch = [
    "/Android/i",
    "/webOS/i",
    "/iPhone/i",
    "/iPad/i",
    "/iPod/i",
    "/BlackBerry/i",
    "/Windows Phone/i",
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

window.addEventListener("keyup", function (event) {
  if (detectMob() === true) {
    //
  }
});

const firebaseConfig = {
  apiKey: "AIzaSyAHiIdq3IyUNLigwjlJGWTU4tHCbhQ7fvY",
  authDomain: "swishfund-511a6.firebaseapp.com",
  projectId: "swishfund-511a6",
  storageBucket: "swishfund-511a6.appspot.com",
  messagingSenderId: "905833913314",
  appId: "1:905833913314:web:c9bc618f9f3d74909437bb",
};

firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
