







































import Vue from "vue";
import TheNavbar from "@/components/TheNavbar.vue";
import TheFooter from "@/components/TheFooter.vue";

export default Vue.extend({
  name: "App",
  components: {
    TheNavbar,
    TheFooter,
  },
  computed: {
    getFields(): string {
      return this.$route.meta?.group;
    },
  },
});
