import { RootState, AuthState } from "@/store/types/";
import { User, UserDetails } from "@/types";
import { ActionContext, Commit, Dispatch } from "vuex";
import router from "@/router";
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import { apiAxios } from "@/store/apiAxios";
import { switchUser } from "@/store/apiAxios";

export const namespaced = true;

const idUserToShow = window.localStorage.getItem("idUserToShow");

export const state = {
  user: null,
  loading: false,
  error: null,
  role: null,
  details: {},
  idUserToShow: idUserToShow !== null ? parseInt(idUserToShow, 10) : null,
};

export const mutations = {
  SET_USER(state: AuthState, user: User): void {
    state.user = user;
  },
  SET_LOADING(state: AuthState, loading: boolean): void {
    state.loading = loading;
  },
  SET_ERROR(state: AuthState, error: null | string): void {
    state.error = error;
  },
  setUserDetails(state: AuthState, details: UserDetails): void {
    state.details = details;
  },

  setIdUserToShow(state: AuthState, id: number | null): void {
    state.idUserToShow = id;
  },
};

export const actions = {
  logout(context: ActionContext<AuthState, RootState>): Promise<any> {
    return AuthService.logout()
      .then(() => {
        window.localStorage.removeItem("idUserToShow");
        window.localStorage.setItem("popupViewed", "false");
        context.commit("setIdUserToShow", null);

        context.commit("SET_USER", null);
        if (router.currentRoute.name !== "login")
          router.push({ path: "/login" });
      })
      .catch((error) => {
        context.commit("SET_ERROR", getError(error));
      });
  },
  async getAuthUser({
    dispatch,
    commit,
    state,
  }: {
    dispatch: Dispatch;
    commit: Commit;
    state: AuthState;
  }): Promise<Record<string, unknown> | undefined> {
    commit("SET_LOADING", true);
    try {
      if (state.idUserToShow !== null) {
        switchUser(state.idUserToShow);
      }
      const response = await AuthService.getAuthUser();
      commit("SET_USER", response.data);
      commit("SET_LOADING", false);
      dispatch("getAuthUserDetails");
      return response.data;
    } catch (error: any) {
      commit("SET_LOADING", false);
      commit("SET_USER", null);
      commit("SET_ERROR", getError(error));
    }
  },

  getAuthUserDetails({ commit }: { commit: Commit }): any {
    return apiAxios
      .get("/api/user/details")
      .then((response) => commit("setUserDetails", response.data.data));
  },

  switchToAdmin({ commit }: { commit: Commit }): void {
    commit("setIdUserToShow", null);
    window.localStorage.removeItem("idUserToShow");

    switchUser(null);
  },

  switchToUser(
    { state, commit }: { state: AuthState; commit: Commit },
    userId: number
  ): void {
    commit("setIdUserToShow", userId);
    window.localStorage.setItem("idUserToShow", `${userId}`);

    switchUser(state.idUserToShow);
    commit("loans/setSelectedLoanId", null, { root: true });
    commit("loans/setFetched", false, { root: true });
    commit("payments/setFetched", false, { root: true });
  },
};

export const getters = {
  getIdUserToShow(): number | null {
    return state.idUserToShow;
  },
  authUser: (state: AuthState): User => {
    return Object.assign(state.user, state.details);
  },
  isAdmin: (state: AuthState): boolean => {
    return state.user ? state.user.isAdmin : false;
  },
  error: (state: AuthState): string | null => {
    return state.error;
  },
  loading: (state: AuthState): boolean => {
    return state.loading;
  },
  loggedIn: (state: AuthState): boolean => {
    return !!state.user;
  },
  guest: (state: AuthState): boolean => {
    return state.idUserToShow !== null;
  },
};
