import { RootState, AccountDetails } from "@/store/types/";
import { ActionContext } from "vuex";
import { Account } from "@/types/";
import { apiAxios } from "@/store/apiAxios";

const state = {
  accounts: [],
};

const mutations = {
  setAccount(state: AccountDetails, accounts: Account[]): void {
    state.accounts = accounts;
  },
};

const getters = {
  getAccount(state: AccountDetails): Account[] {
    return state.accounts;
  },
};

const actions = {
  async fetchAccount(
    context: ActionContext<AccountDetails, RootState>,
    { id }: { id: number }
  ): Promise<any> {
    return apiAxios({
      method: "get",
      url: `/api/company/${id}`,
    })
      .then((response) => {
        context.commit("setAccount", response.data);
        return response.data;
      })
      .catch((error) => console.log(error));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
