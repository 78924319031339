






















































































































































































































































































import Vue from "vue";
import LocaleSwitcher from "@/components/navbar/LocaleSwitcher.vue";
import AuthDropdown from "@/components/navbar/AuthDropdown.vue";
import { mapGetters } from "vuex";
import { Loan } from "@/types";
import AuthService from "@/services/AuthService";

export default Vue.extend({
  name: "TheNavbar",
  components: {
    LocaleSwitcher,
    AuthDropdown,
  },
  computed: {
    ...mapGetters({
      activeLoans: "loans/getActiveLoans",
      loans: "loans/getLoans",
      user: "auth/authUser",
      hasSwitchToUser: "auth/guest",
    }),
    showFeature(): boolean {
      if (process.env.VUE_APP_SHOW_FEATURE !== "true") {
        return false;
      }

      return true;
    },
    showSwitch(): boolean {
      if (process.env.VUE_APP_SHOW_ADMIN_PANEL !== "true") {
        return false;
      }

      return this.user.role_id === 1 && this.hasSwitchToUser;
    },
    showAdminBar(): boolean {
      if (process.env.VUE_APP_SHOW_ADMIN_PANEL !== "true") {
        return false;
      }

      return this.user.role_id === 1 && !this.hasSwitchToUser;
    },
    showSecondBar(): boolean {
      if (process.env.VUE_APP_SHOW_ADMIN_PANEL !== "true") {
        return true;
      }

      return (
        (this.user.role_id !== 1 && this.user.role_id !== 2) ||
        this.hasSwitchToUser
      );
    },
    selectedLoan: {
      get(): number | null {
        return this.$store.getters["loans/selectedLoanId"];
      },
      set(value: number) {
        this.$store.commit("loans/setSelectedLoanId", value);
      },
    },
    loan(): Loan {
      return this.loans.filter(
        (loan: Loan) => loan.ac_deal_id == this.selectedLoan
      )[0];
    },
  },
  methods: {
    switchToAdmin() {
      this.$store.dispatch("auth/switchToAdmin");
      this.$router.push({ name: "Admin Dashboard" });
    },
    logout() {
      const payload = {
        id: this.user.id,
        type: "logout",
        step: "logout",
      };
      AuthService.addLog(payload);
      this.$store.dispatch("auth/logout");
    },
  },
});
/**
 * <router-link>'s tag prop is deprecated and has been removed in Vue Router 4. Use the v-slot API to remove this warning
 * Console warning refers to bootstrap-vue internal usage of :tag.
 * @see https://github.com/bootstrap-vue/bootstrap-vue/issues/6471
 * Suggested curse of action would be to wait for bootstrap-vue to solve the issue, or submit a PR
 */
