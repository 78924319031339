import axios, { AxiosRequestConfig } from "axios";

export let apiAxios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
});

export const switchUser = (userId: number | null = null): void => {
  const config: AxiosRequestConfig = {
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true,
  };

  if (userId !== null) {
    config.headers = { "X-USER": `${userId}` };
  }

  apiAxios = axios.create(config);
};
