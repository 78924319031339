import { LogState } from "./types";
import { Log, Report } from "@/types/";
import { apiAxios } from "./apiAxios";
import { Commit } from "vuex";
import i18n from "@/i18n";

const state = {
  logs: null as any,
  chartLogs: null as any,
  fetched: null as unknown as boolean,
  report: null as any,
  filter: "login",
};

const mutations = {
  setLog(state: LogState, logs: Log[]): void {
    state.logs = logs;
  },

  setFilter(state: LogState, filter: string): void {
    state.filter = filter;
  },

  setChartLog(state: LogState, chartLogs: Log[]): void {
    state.chartLogs = chartLogs;
  },

  setReport(state: LogState, report: Report): void {
    state.report = report;
  },

  setFetchedLog(state: LogState, fetched: boolean): void {
    state.fetched = fetched;
  },
};

const actions = {
  async fetchDailyLogs({
    commit,
    state,
  }: {
    commit: Commit;
    state: LogState;
  }): Promise<any> {
    if (state.fetched === true) return;
    return await apiAxios
      .get("/api/daily-logs", {
        params: { filter: state.filter, locale: i18n.locale },
      })
      .then((r) => {
        commit("setChartLog", r.data);
        commit("setFetchedLog", true);
      });
  },

  fetchSingleUserLogs({ commit }: { commit: Commit }, id: string): any {
    return apiAxios.get("/api/admin/user-log", { params: { id } }).then((r) => {
      commit("setLog", r.data);
    });
  },

  async fetchReportUserLoan(
    { commit }: { commit: Commit },
    id: string
  ): Promise<any> {
    return await apiAxios
      .get("/api/admin/report-user-loan", { params: { id } })
      .then((r) => {
        commit("setReport", r.data.data);
      });
  },
};

const getters = {
  getLogs(state: LogState): Log[] {
    if (state.logs !== null) {
      state.logs.sort(
        (a: Log, b: Log) =>
          new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
      );
    }
    return state.logs;
  },

  getChartLogs(state: LogState): Log[] {
    return state.chartLogs;
  },

  getFilter(state: LogState): string {
    return state.filter;
  },

  getReport(state: LogState): Report {
    return state.report;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
