interface Error {
  message: string;
  name: string | null;
  response: {
    data: {
      errors: string;
      message: string;
    };
    status: string;
    headers: string[];
  };
  config: Record<string, unknown>;
}
export interface ErrorResult {
  email?: string[] | undefined;
  password?: string[] | undefined;
  message?: string | undefined;
}

export const getError = (error: Error): ErrorResult => {
  const errorMessage = { message: "API Error, please try again." };

  if (error.name === "Fetch User") {
    return { message: error.message };
  }

  if (error.name === "password") {
    return { message: error.message };
  }

  if (!error.response) {
    // console.error(`API ${error.config.url} not found`);
    return errorMessage;
  }
  if (process.env.NODE_ENV === "development") {
    console.error(error.response.data);
    console.error(error.response.status);
    console.error(error.response.headers);
  }
  if (error.response.data) {
    if (error.response.data.message.split("(")[0]) {
      return { message: error.response.data.message.split("(")[0] };
    }
    return { message: error.response.data.message };
  }

  return errorMessage;
};

export const localizeDate = (
  timestamp: number,
  countryCode = "nl-NL"
): string => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  } as const;

  return new Date(timestamp).toLocaleDateString(countryCode, options);
};
