import { RootState } from "@/store/types/";
import { Application, Document } from "@/types";
import { ActionContext } from "vuex";
import { apiAxios } from "@/store/apiAxios";

const state = <Application>{
  applicant: {
    id: null,
  },
  company: {
    id: null,
  },
  loan: {
    id: null,
    amount: 5000,
    purpose: "",
    correctCompanyInfo: false,
    documents: [
      {
        file: null,
        name: "mt940",
        accept: "image/, .pdf",
        mandatory: true,
        uploading: false,
        visible: false,
      },
      {
        file: null,
        name: "debit-list",
        accept: "image/, .pdf",
        mandatory: false,
        uploading: false,
        visible: false,
      },
      {
        file: null,
        name: "creditor-list",
        accept: "image/, .pdf",
        mandatory: false,
        uploading: false,
        visible: false,
      },
      {
        file: null,
        name: "recent-income-tax-return",
        accept: "image/, .pdf",
        mandatory: false,
        uploading: false,
        visible: false,
      },
      {
        file: null,
        name: "overview-debts-loans",
        accept: "image/, .pdf",
        mandatory: false,
        uploading: false,
        visible: false,
      },
    ],
  },
};

const mutations = {
  updateLoan(state: Application, loan: Application): void {
    state = loan;
  },
  setLoanId(state: Application, loanId: number): void {
    state.loan.id = loanId;
  },
  updateDocument(state: Application, document: Document): void {
    state.loan.documents = state.loan.documents.map((d) => {
      if (d.name === document.name) {
        return document;
      } else {
        return d;
      }
    });
  },
};
const getters = {
  getLoan(): Application {
    return state;
  },
};

const actions = {
  saveLoan(
    context: ActionContext<Application, RootState>,
    loanApplication: Application
  ): any {
    loanApplication.applicant.id = context.rootState.auth.details.id;
    loanApplication.company.id = context.rootState.auth.details.accountId;
    if (loanApplication.loan.id === null) {
      // create
      return apiAxios.post("/api/loans", loanApplication).then((res) => {
        context.commit("setLoanId", parseInt(res.data.loan.id));
      });
    } else {
      // update
      return apiAxios.patch("/api/loans", loanApplication);
    }
  },
  saveDocument(_: ActionContext<Application, RootState>, document: File): any {
    return apiAxios.post("/api/loans/documents", document, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
